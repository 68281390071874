import React from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { Field, ErrorMessage } from "formik";

const PickOneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > * {
    margin-right: 0.5rem;
    flex: 100%;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const SingleOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #121212;
  color: white;

  height: 24px;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  box-shadow: 0 0 0 5px ${props => transparentize(1, props.theme.high)};
  transition: all 0.3s ease;

  cursor: pointer;

  ${props =>
    props.checked &&
    css`
      background-color: ${props => props.theme.high};
      color: white;
      border-color: ${props => props.theme.high};
    `} &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.high};
  }
`;

const PickOne = props => {
  const { options } = props;

  return (
    <PickOneContainer>
      {options.map(option => (
        <SingleOption
          tabIndex="0"
          onClick={() =>
            props.form.setFieldValue(props.field.name, option.value)
          }
          checked={props.field.value === option.value}
        >
          <i className="material-icons">{option.icon}</i>
        </SingleOption>
      ))}
    </PickOneContainer>
  );
};

const MyField = props => {
  const { floated, label, name, type, required } = props;

  let Component = props.component || "input";

  switch (type) {
    case "pickOne":
      Component = PickOne;
      break;
    case "textarea":
      Component = "textarea";
      break;
  }

  return (
    <Container>
      <Label floated={floated}>
        {label || name} {required && <sup>*</sup>}
      </Label>
      <input {...props} />
      <ErrorContainer>
        <ErrorMessage name={name} />
      </ErrorContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  font-size: 1rem;

  input,
  textarea {
    width: 100%;
    font-size: 1rem;
    height: 3em;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0 0.5em;

    box-shadow: 0 0 0 8px ${props => transparentize(1, props.theme.high)};
    transition: all 0.3s ease-in;

    &:focus {
      transition-timing-function: ease-out;
      box-shadow: 0 0 0 2px ${props => props.theme.high};
    }

    @media screen and (min-width: 920px) {
      font-size: 0.85rem;
    }
  }

  input[type="file"] {
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  textarea {
    height: 5rem;
    padding: 0.5em;
  }
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  color: ${props => props.theme.red};
  margin-top: 0.5rem;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;

  margin-bottom: 0.5rem;
  font-family: "Titillium Web", sans-serif;

  sup {
    color: ${props => props.theme.red};
  }

  ${props =>
    props.floated &&
    css`
      color: #333;

      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;

      padding: 0 0.5rem;
      margin: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 3;
    `};
`;

export default MyField;
