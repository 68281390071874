import React from "react";
import ReactMapboxGl, { Feature, Layer } from "react-mapbox-gl";
import styled, { css } from "styled-components";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import { TweenLite, Expo } from "gsap";

import axios from "axios";

import { userSelector } from "../redux/user";

import apiClient from "../utility/apiClient";

import Page from "../components/Page";
import MyField from "../components/Field";

import { fetch } from "whatwg-fetch";

if (typeof window.fetch === "undefined") {
  window.fetch = fetch;
}

const validationSchema = Yup.object().shape({
  descr: Yup.string().required("Questo campo è obbligatorio."),
  date: Yup.string("Inserisci una data valida.").required(
    "Questo campo è obbligatorio."
  ),
});

const TOKEN =
  "pk.eyJ1IjoiZ2dzaW1tIiwiYSI6ImNqbmtjcThnbjE4cmYzcXFyODczdjcza2QifQ.4K37C9jipyJuHbEk0jOmVQ";

const Map = ReactMapboxGl({
  accessToken: TOKEN,
});

const INITIAL_VALUES = null && {
  date: "2018-12-12",
  descr: "lorem ipsum",
};

class MapView extends React.Component {
  state = {
    selected: null,
    isDragging: false,
    coordinates: [12.3959135, 41.9102415],
  };

  center = [12.3959135, 41.9102415];
  zoom = [5];
  map = React.createRef();

  coordinates = [12.3959135, 41.9102415];

  componentDidMount = async () => {
    try {
      await apiClient({ useCache: false })({ action: "book" });
    } catch (err) {}
  };

  handleMapMove = (_, e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();

    this.center = [center.lng, center.lat];
    this.zoom = [zoom];
  };

  handleDragStart = e => {
    this.setState({
      isDragging: true,
      query: "",
    });
  };

  handleDragEnd = e => {
    const { lat, lng } = e.lngLat;

    this.setState({
      coordinates: [lng, lat],
      isDragging: false,
    });
  };

  handleSubmit = async values => {
    const { user } = this.props;
    const {
      coordinates: [lng, lat],
    } = this.state;
    const { descr, date, allegato } = values;

    const formData = new FormData();

    const data = {
      ...user,
      lng,
      lat,
      descr,
      data: date,
      action: "book",
    };

    formData.append("allegato", allegato);

    for (var key in data) {
      formData.append(key, data[key]);
    }

    console.log("submitting");

    try {
      const res = await axios.post(
        "https://book.primis.live/ms/1-0/index.php",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      this.setState({
        submitted: true,
        id: res.data.data.id,
      });
    } catch (err) {
      console.log(err);
      this.setState({ error: err.response.data.error.code });
    }
  };

  handleSuggestionSelection = (_, lat, lng) => {
    const obj = { y: window.scrollY };
    TweenLite.to(obj, 0.75, {
      y: 0,
      ease: Expo.easeOut,
      onUpdate: () => window.scrollTo(0, obj.y),
    });

    this.center = [lng, lat];
    this.zoom = [9];

    this.setState({
      coordinates: [lng, lat],
    });
  };

  dismissModal = e => {
    e.preventDefault();

    this.setState({
      submitted: false,
      id: null,
    });
  };

  render() {
    const { coordinates, isDragging, submitted, id, error } = this.state;

    return (
      <Page>
        <MapContainer>
          <Map
            zoom={this.zoom}
            center={this.center}
            style="mapbox://styles/ggsimm/cjnvvrkr702pk2sl9y0elshat"
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            pitch={[70]}
            ref={this.map}
            onMoveEnd={this.handleMapMove}
            preserveDrawingBuffer={true}
          >
            <Layer
              type="circle"
              layout={{}}
              paint={{
                "circle-radius": isDragging ? 5 : 8,
                "circle-color": "#00CFFF",
                "circle-stroke-width": isDragging ? 26 : 10,
                "circle-stroke-color": "rgba(255, 255, 255, .1)",
              }}
            >
              <Feature
                coordinates={coordinates}
                draggable
                onDragStart={this.handleDragStart}
                onDragEnd={this.handleDragEnd}
              />
            </Layer>
          </Map>
        </MapContainer>
        <FormContainer>
          <Formik
            onSubmit={this.handleSubmit}
            values={{
              coords: coordinates,
            }}
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
          >
            {({ setFieldValue }) => (
              <Form>
                <S.Title>Richiedi intervento</S.Title>
                <S.SmallTitle>
                  Posiziona il marker <CC marker /> sul luogo dell'intervento o
                  cerca un indirizzo.
                </S.SmallTitle>
                <Search>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <MapboxAutocomplete
                      publicKey={TOKEN}
                      onSuggestionSelect={this.handleSuggestionSelection}
                      country="it"
                      resetSearch={false}
                    />
                  </div>
                  <Icon>
                    <i className="material-icons">location_searching</i>
                  </Icon>
                </Search>
                <S.SmallTitle>
                  Le zone delimitate da <CC /> richiedono autorizzazioni
                  speciali e possono richiedere fino a 40 giorni.
                </S.SmallTitle>
                <MyField
                  required
                  type="textarea"
                  name="descr"
                  label="Dettaglio intervento"
                />
                <MyField required name="date" label="Data desiderata" />

                <MyField
                  name="allegato"
                  label="Allegato"
                  component={() => (
                    <input
                      type="file"
                      onChange={e => {
                        setFieldValue("allegato", e.currentTarget.files[0]);
                      }}
                    />
                  )}
                />
                {error && (
                  <SubmissionError>
                    <strong>Errore:</strong> {error}
                  </SubmissionError>
                )}
                <Button>Richiedi Intervento</Button>
              </Form>
            )}
          </Formik>
        </FormContainer>
        {submitted && (
          <Background>
            <Success>
              <div>
                <S.Title>Richiesta intervento inviata con successo</S.Title>
                <S.SmallTitle>ID richiesta: {id}</S.SmallTitle>

                <a href="#" onClick={this.dismissModal}>
                  Chiudi
                </a>
              </div>
            </Success>
          </Background>
        )}
      </Page>
    );
  }
}

const MapContainer = styled.div`
  height: 70vh;
  width: 100vw;

  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  @media screen and (min-width: 920px) {
    height: 100vh;
  }
`;

const FormContainer = styled.div`
  z-index: 10;
  background-color: rgba(30, 30, 30, 0.95);
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #000;

  background-color: white;
  color: #333;

  position: relative;

  margin-left: 1rem;
  margin-right: 1rem;

  margin-top: -12vh;
  margin-bottom: 2rem;

  @media screen and (min-width: 920px) {
    position: fixed;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 360px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Search = styled.div`
  z-index: 10;
  width: 100%;
  position: relative;

  margin-bottom: 1.5rem;
  margin-top: -0.75rem;

  input {
    height: 3em;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0 1em;
    width: 100%;

    transition: all 0.3s ease-in;

    padding-left: 2.5rem;
    margin-bottom: 0;

    font-size: 1rem;

    &:focus {
      transition-timing-function: ease-out;
      box-shadow: 0 0 0 2px ${props => props.theme.high};
    }

    @media screen and (min-width: 900px) {
      font-size: 0.85rem;
    }
  }

  .react-mapbox-ac-menu {
    width: 100%;
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #ccc;
    color: #121212;
    margin-top: 0;
  }

  .react-mapbox-ac-input {
    right: 1rem;
    top: 1rem;
    z-index: 10;
  }

  .react-mapbox-ac-suggestion {
    font-size: 18px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 12px;
  }

  .react-mapbox-ac-suggestion:hover {
    background-color: ${props => props.theme.high};
    color: white;
  }
`;

const Icon = styled.div`
  position: absolute;
  left: 0.75rem;
  color: #999;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    font-size: 14px;
  }
`;

const S = {
  Title: styled.h3`
    font-size: 0.85rem;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
  `,
  SmallTitle: styled.h4`
    font-size: 0.75rem;
    color: #333;
    margin-bottom: 2rem;
    line-height: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  `,
};

const CC = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px dashed ${props => props.theme.red};
  border-radius: 50%;
  transform: translateY(25%);
  margin: 0 0.5rem;

  ${props =>
    props.marker &&
    css`
      background-color: ${props => props.theme.primis};
      border: none;
    `};
`;

const SubmissionError = styled.div`
  background-color: ${props => props.theme.red};
  color: white;
  padding: 1em;
  font-size: 12px;
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 199;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Success = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;

  top: 50%;
  left: 50%;

  width: 80%;
  max-width: 360px;

  font-size: 12px;

  transform: translateX(-50%) translateY(-50%);
  border-radius: 5px;

  padding: 2rem;

  background-color: white;

  color: #333;

  z-index: 200;

  a {
    color: ${props => props.theme.high};
  }
`;

const Button = styled.button`
  padding: 1rem;
  background-color: ${props => props.theme.high};
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  margin-right: 0;

  cursor: pointer;
`;

export default connect(state => {
  return {
    user: userSelector(state),
  };
})(MapView);
