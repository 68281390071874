import { createActions, handleActions } from 'redux-actions'

import apiClient from '../utility/apiClient'

/* -- actions -- */
export const fetchRilievi = (projectId) => async dispatch => {

  dispatch(fetchRilieviRequest(projectId))

  try {
    const data = await apiClient({ cache: false })({
      action: "get_rilievi",
      data: {
        id_progetto: projectId
      }
    })

    dispatch(fetchRilieviSucceeded(data))
  } catch (err) {
    dispatch(fetchRilieviFailed(err))
  }
}

export const {
  fetchRilieviRequest,
  fetchRilieviSucceeded,
  fetchRilieviFailed
} = createActions({
  FETCH_RILIEVI_REQUEST: (projectId) => ({ projectId }),
  FETCH_RILIEVI_SUCCEEDED: (rilievi) => ({ rilievi }),
  FETCH_RILIEVI_FAILED: (err) => ({ err }),
})

/* -- reducers --  */
export const reducer = handleActions({
  [fetchRilievi]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [fetchRilieviSucceeded]: (state, { payload: { rilievi } }) => {
    return {
      ...state,
      loading: false,
      data: rilievi,
    }
  }
}, {
    data: [],
    loading: false
  })

/* -- selectors -- */
export const rilieviSelector = state => state.rilievi.data
