import axios from "axios";
import qs from "qs";
import memoize from "fast-memoize";

import { store, persistor } from "../redux/";
import { userSelector, updateToken } from "../redux/user";

const getKey = memoize((action, data) => {
  return `${action}/${JSON.stringify({ data })}`;
});

const cache = {};

const defaultOptions = {
  url: "index.php",
  useCache: true,
  headers: {
    "Content-type": "application/x-www-form-urlencoded",
  },
};

const client = (passedOptions = { headers: {} }) => ({
  data,
  action,
  ...props
}) => {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  };

  const { url, useCache } = options;

  const user = userSelector(store.getState());

  const cacheKey = getKey(
    action,
    data,
    parseInt((new Date().getTime() / 1000) * 60, 10)
  );

  if (useCache && cacheKey in cache) {
    return Promise.resolve(cache[cacheKey]);
  }

  console.log(passedOptions.headers);

  const client = axios({
    baseURL: "https://book.primis.live/ms/1-0/",
    url,
    method: "POST",
    headers: {
      ...defaultOptions.headers,
      ...passedOptions.headers,
    },
    ...props,
    data: qs.stringify({ action, ...data, ...user }),
  });

  return client
    .then(x => x.data)
    .then(res => {
      const { token } = res;

      if (token) {
        store.dispatch(updateToken(token));
      }

      return res.data;
    })
    .catch((err, x) => {
      if (err.response.data.error.code === "Wrong token") {
        persistor.purge();
        store.dispatch({ type: "USER_LOGOUT" });
      } else {
        throw new Error(err.response.data.error.code);
      }
    });
};

export default client;
