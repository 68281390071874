import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import Login from './pages/Login'
import RichiediIntervento from './pages/RichiediIntervento'

import LoggedRoute from './components/LoggedRoute'

import { store, persistor } from './redux'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={{
            primis: "#00CFFF",
            high: "#e74c3c",
            green: "#2ecc71",
            red: "#e74c3c",
          }}>
            <BrowserRouter>
              <Switch>
                <Route path="/login" exact component={Login} />
                <LoggedRoute path="/" exact component={RichiediIntervento} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>

        </PersistGate>
      </Provider>
    )
  }
}

export default App;
