import { createActions, handleActions } from 'redux-actions'

import apiClient from '../utility/apiClient'

/* -- actions -- */
export const fetchMarkers = ({ rilievoId }) => async dispatch => {

  dispatch(fetchMarkersRequest(rilievoId))

  try {
    const data = await apiClient({ cache: false })({
      action: "get_marker",
      data: {
        id_rilievo: rilievoId
      }
    })

    dispatch(fetchMarkersSucceeded(data))
  } catch (err) {
    dispatch(fetchMarkersFailed(err))
  }
}

export const {
  fetchMarkersRequest,
  fetchMarkersSucceeded,
  fetchMarkersFailed
} = createActions({
  FETCH_MARKERS_REQUEST: (rilievoId) => ({ rilievoId }),
  FETCH_MARKERS_SUCCEEDED: (markers) => ({ markers }),
  FETCH_MARKERS_FAILED: (err) => ({ err }),
})

/* -- reducers --  */
export const reducer = handleActions({
  [fetchMarkers]: state => {
    return {
      ...state,
      loading: true,
    }
  },
  [fetchMarkersSucceeded]: (state, { payload: { markers } }) => {
    return {
      ...state,
      loading: false,
      data: markers,
    }
  }
}, {
    data: [],
    loading: false
  })

/* -- selectors -- */
export const markersSelector = state => state.markers.data
