import { createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";

const defaultState = null;

/* -- actions -- */
export const { setUser, updateToken } = createActions({
  SET_USER: data => data,
  UPDATE_TOKEN: token => token,
});

/* -- reducers -- */
export const reducer = handleActions(
  {
    SET_USER: (state, { payload: data }) => {
      return {
        ...data,
      };
    },
    UPDATE_TOKEN: (state, { payload: token }) => {
      return {
        ...state,
        token,
      };
    },
  },
  defaultState
);

/* -- selectors -- */
export const userSelector = state => state.user;

export const usernameSelector = createSelector(userSelector, user => user.nome);

export const tokenSelector = createSelector(userSelector, user => user.token);
