import { createActions, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import { combineReducers } from 'redux'

/* -- actions -- */
export const { pushSidekickContent, closeSidekick } = createActions({
  PUSH_SIDEKICK_CONTENT: () => null,
  CLOSE_SIDEKICK: () => null,
})

/* -- reducers --  */
export const sidekickReducer = handleActions({
  PUSH_SIDEKICK_CONTENT: (state) => {
    return {
      ...state,
      isOpen: true,
    }
  },
  CLOSE_SIDEKICK: (state) => {
    return {
      ...state,
      isOpen: false,
    }
  }
}, {
    isOpen: false,
  })

export const reducer = combineReducers({
  sidekick: sidekickReducer
})

/* -- selectors -- */
export const metaSelector = state => state.meta

export const sidekickSelector = createSelector(
  metaSelector,
  state => state.sidekick
)

export const sidekickOpenSelector = createSelector(
  sidekickSelector,
  state => state.isOpen
)
