import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import logo from '../logo.svg'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`

const Body = styled.div`
  flex: 100% 0 1;
  position: relative;
`

const Logo = styled.div`
  display: block;
  width: 100%;
  overflow: visible;
  position: fixed;

  top: 2rem;
  left: 2rem;
  width: 26px;

  z-index: 3;

  img {
    height: auto;
    width: 100%;
  }
`

function Page(props) {

  return (
    <Container>
      <Logo as={Link} to="/">
        <img src={logo} alt="Primis" />
      </Logo>
      <Body>
        {props.children}
      </Body>
    </Container>
  )
}


export default Page
