import { createStore, applyMiddleware, combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from "redux-thunk"

import * as user from "./user"
import * as meta from "./meta"
import * as rilievi from "./rilievi"
import * as markers from "./markers"

const defaultState = {}

const rootReducer = combineReducers({
  user: user.reducer,
  meta: meta.reducer,
  rilievi: rilievi.reducer,
  markers: markers.reducer,
})

const reducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return defaultState
  }

  return rootReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'rilievi', 'markers']
}

const persistedReducer = persistReducer(persistConfig, reducer)

const composeEnhancers = composeWithDevTools({
  name: "primis-store",
  actionCreators: {
    setUser: user.setUser,
  },
  features: {
    persist: true,
    export: false,
    pause: false,
    test: false,
  },
})

export const store = createStore(
  persistedReducer,
  defaultState,
  composeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store)
