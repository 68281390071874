import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { userSelector } from '../redux/user'

const mapStateToProps = state => ({
  isLogged: userSelector(state)
})

export default connect(mapStateToProps)(
  ({ component, isLogged, path, exact, ...props }) => {
    const Component = component

    return isLogged ? <Component {...props} /> : <Redirect to="/login" />
  }
)
