import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import Field from "../components/StaticField";

import apiClient from "../utility/apiClient";

import logo from "../logo.svg";

import { setUser, userSelector } from "../redux/user";

function Login(props) {
  if (props.userLogged) return <Redirect to="/" />;

  const [values, setValues] = useState({
    email: null,
    pw: null,
  });

  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    const { setUser } = props;

    e.preventDefault();

    try {
      const data = await apiClient({
        url: "login.php",
      })({ data: values });

      setUser(data);
    } catch (err) {
      setError(err.message);
    }
  }

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });

    setError(null);
  }

  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        <form onSubmit={handleSubmit}>
          <Field
            big
            label="Email"
            name="email"
            value={values["email"]}
            onChange={e => handleChange("email", e.target.value)}
          />

          <Field
            big
            label="Password"
            name="pw"
            value={values["pw"]}
            type="password"
            onChange={e => handleChange("pw", e.target.value)}
          />

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <SubmitBtn type="submit"> Login </SubmitBtn>
        </form>
      </Wrapper>
    </Container>
  );
}

const mapStateToProps = state => ({
  userLogged: userSelector(state),
});

export default connect(
  mapStateToProps,
  { setUser }
)(Login);

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
`;

const Wrapper = styled.div`
  flex: 40% 0 0;
  padding: 5rem;
`;

const SubmitBtn = styled.button`
  font-size: 12px;
  color: white;
  background-color: ${props => props.theme.high};
  border: none;
  padding: 1rem 3rem;
  width: 100%;
  border-radius: 3px;
  margin-top: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 6px 5px rgba(0, 0, 0, 0);

  letter-spacing: 0.12rem;

  &:hover {
    background-color: #068dc7;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 32px;
  margin-bottom: 8rem;
`;

const ErrorMessage = styled.div`
  padding: 0.5rem;
  font-size: 11px;
  background-color: ${props => props.theme.red};
  color: white;

  margin-top: 0.5rem;
`;
